import React from "react";
import { SnackbarProvider } from "notistack";
import { Button, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// routes
import Routers from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import "bootstrap/dist/css/bootstrap.min.css";
//Dark theme like DD
import "./assets/css/index.css";
import "./assets/css/style.css";
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import { ContextPGIMode } from "./Hooks/PGIModeContext";
import { get_app_headers } from "./config/config";
import { Helmet } from "react-helmet";
import { ContentSettingState } from "./Hooks/ContentContext/ContentSettingState";

export default function App() {
  const notistackRef = React.createRef();
  const headers = get_app_headers();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <ContextPGIMode>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <SnackbarProvider
          ref={notistackRef}
          action={(key) => (
            <Button
              className="snackbar-cross-icon"
              onClick={onClickDismiss(key)}
            >
              <CloseIcon />
            </Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
          maxSnack={3}
        >
          <Helmet>
            <title>{headers && headers.title}</title>
            <meta name="description" content={headers && headers.description} />
            <link
              rel="icon"
              type="image/x-icon"
              href={headers && headers.fav_icon}
            />
          </Helmet>
          <ContentSettingState>
            <Routers />
          </ContentSettingState>
        </SnackbarProvider>
      </ThemeConfig>
    </ContextPGIMode>
  );
}
