import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import {
  ForgetPassword,
  Programmes,
  Profile,
  Calender,
  SupportTicket,
  LessonsDetail,
  LessonListing,
  ProgrammRecordingDetail,
  StudySessionRecordingDetail,
  SupportTicketDetail,
  HelpingVideos,
  HelpingVideoDetail,
} from "./pages/index";
import EditProfile from "./pages/Profile/EditProfile";
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import {
  DeleteNote,
  StudySessionListing,
} from "./components/_dashboard/programmes";
import BillingDetail from "./components/Billing/BillingDetails";
import AllTransaction from "./components/Transactions/AllTransaction";
import TransactionInformation from "./components/Transactions/TransactionInformation";
import ManageCard from "./components/ChangeCard/ManageCard";
import ChangeCreditCard from "./components/ChangeCard/ChangeCreditCard";
import PaymentRequests from "./components/PaymentRequests/PaymentRequests";
import PendingRequests from "./components/PaymentRequests/PendingRequests";
import ActiveRequests from "./components/PaymentRequests/ActiveRequests";
import DashboardApp from "./pages/DashboardApp";
import CancelSubscription from "./pages/CancelSubscription";
import ActiveSubscriptionList from "./components/PaymentRequests/ActiveSubscriptionList";
import LoginByAdmin from "./pages/LoginByAdmin";
import SettingsLayout from "./layouts/SettingsLayout";
import ProgrammesCategoryList from "./components/_dashboard/programmes/ProgrammesCategoryList";
import LessonsDetailParent from "./pages/Programmes/LessonsDetailParent";
import ProgrammesCategoryListGrand from "./components/_dashboard/programmes/ProgrammesCategoryListGrand";
import ProgrammesCategoryListParent from "./components/_dashboard/programmes/ProgrammesCategoryListParent";
import ProgrammesCategoryListChild from "./components/_dashboard/programmes/ProgrammesCategoryListChild";

// ----------------------------------------------------------------------
const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};

export default function Router() {
  return (
    <Routes>
      <Route path="/login-by-admin/:token" element={<LoginByAdmin />} />
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<DashboardApp />} />

        {/* programmes Start */}
        <Route path="/programmes" element={<Programmes />} />
        <Route path="/programmes/:slug" element={<ProgrammesCategoryList />} />
        <Route
          path="/programmes/:slug/:grand_slug"
          element={<ProgrammesCategoryListGrand />}
        />
        <Route
          path="/programmes/:slug/:grand_slug/:parent_slug"
          element={<ProgrammesCategoryListParent />}
        />
        <Route
          path="/programmes/:slug/:grand_slug/:parent_slug/:child_slug"
          element={<ProgrammesCategoryListChild />}
        />
        <Route
          path="/programmes/:slug/:grand_slug/:parent_slug/:child_slug/:detail_slug"
          element={<LessonsDetail />}
        />
        {/* <Route
          path="/programmes/:slug/:lesson_slug"
          element={<LessonListing />}
        />
        <Route
          path="/programmes/:slug/:lesson_slug/:detail_slug"
          element={<LessonsDetail />}
        /> */}
        <Route
          path="/programmes/:slug/:grand_slug/:parent_slug/category-child/:detail_slug"
          element={<LessonsDetailParent type="category-child" />}
        />
        <Route
          path="/programmes/:slug/category-grand/:detail_slug"
          element={<LessonsDetailParent type="category-grand" />}
        />
        <Route
          path="/programmes/:slug/:grand_slug/category-parent/:detail_slug"
          element={<LessonsDetailParent type="category-parent" />}
        />
        {/* programmes End */}

        {/* calendar Start */}
        <Route path="/calendar" element={<Calender />} />
        {/* calendar End */}

        {/* Support Tickets Start */}
        <Route path="/support-tickets" element={<SupportTicket />} />
        <Route path="/support-ticket/:id" element={<SupportTicketDetail />} />
        {/* Support Tickets End */}

        {/* Profile Start */}
        <Route path="/edit-profile" element={<EditProfile />} />

        {/* Profile End */}

        <Route element={<SettingsLayout />}>
          <Route path="/manage-card" element={<ManageCard />} />
          <Route path="/change-card" element={<ChangeCreditCard />} />
          <Route
            path="/pending-request/:request_slug"
            element={<PendingRequests />}
          />
          <Route path="/manage-subscription" element={<CancelSubscription />} />
          <Route
            path="/manage-subscription/:id"
            element={<ActiveSubscriptionList />}
          />
          <Route path="/payment-request" element={<PaymentRequests />} />
          <Route path="/transaction" element={<AllTransaction />} />
          <Route
            path="/active-request/:request_slug"
            element={<ActiveRequests />}
          />
        </Route>
        <Route
          path="/programmes/:program_slug/lessons"
          element={<LessonListing />}
        />
        <Route
          path="/study-session/:sesson_slug/study-session-recordings"
          element={<StudySessionListing />}
        />
        <Route
          path="/study-session/:sesson_slug/study-session-recording/:recording_slug"
          element={<StudySessionRecordingDetail />}
        />
        <Route path="/lessons/:lesson_slug" element={<LessonsDetail />} />
        <Route
          path="/recordings/:recording_slug"
          element={<ProgrammRecordingDetail />}
        />
        <Route
          path="/lessons-recordings/:recording_slug"
          element={<ProgrammRecordingDetail />}
        />
        <Route
          path="/programmes/:program_slug/lessons/:lesson_slug/:id"
          element={<DeleteNote />}
        />

        <Route path="/profile" element={<Profile />} />
        <Route
          path="/transaction/transaction-detail/:slug"
          element={<TransactionInformation />}
        />
        <Route
          path="/billing/billing-detail/:billslug"
          element={<BillingDetail />}
        />

        <Route path="/helping-videos" element={<HelpingVideos />} />
        <Route path="/helping-videos/:slug" element={<HelpingVideoDetail />} />
      </Route>

      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
